<template>
  <div class="admin-container">
    <router-view />
  </div>
</template>
<script>
export default {
    
};
</script>
<style lang="scss">
.admin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>